/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/bootstrap.min.css';
import './styles/theme.css';
import './styles/bootstrap-reset.css';
import './styles/font-awesome.css';
import './styles/flexslider.css';
import './styles/jquery.bxslider.css';
import './styles/animate.css';
import './styles/owl.carousel.css';
import './styles/owl.theme.css';
import './styles/superfish.css';
import './styles/component.css';
import './styles/style.css';
import './styles/style-responsive.css';
import './styles/parallax-slider.css';

import './styles/app.css';

// const modernizr = require("modernizr");

// import './jslibs/parallax-slider/modernizr.custom.28468.js';

// const modernizr = require("./jslibs/parallax-slider/modernizr.custom.28468.js")();

import './jslibs/html5shiv.js';
import './jslibs/jquery-1.8.3.min.js';
import './jslibs/bootstrap.js';
import './jslibs/hover-dropdown.js';
import './jslibs/jquery.flexslider.js';
import './jslibs/bxslider/jquery.bxslider.js';
import './jslibs/jquery.parallax-1.1.3.js';
// import './jslibs/wow.min.js';
import './jslibs/owlcarousel/owl.carousel.js';
import './jslibs/jquery.easing.min.js';
import './jslibs/link-hover.js';
import './jslibs/superfish.js';
import './jslibs/parallax-slider/jquery.cslider.js';
import './jslibs/turn.js';
import './jslibs/common-scripts.js';

require('./jslibs/wow.min.js');

// import './js/main.js';

// start the Stimulus application
import './bootstrap';


$(function() {
    $('#da-slider').cslider({
        autoplay    : true,
        bgincrement : 100
    });

});

jQuery(document).ready(function() {
    $('.bxslider1').bxSlider({
        auto: 1,
        minSlides: 5,
        maxSlides: 6,
        slideWidth: 360,
        slideMargin: 2,
        moveSlides: 4,
        responsive: true,
        nextSelector: '#slider-next',
        prevSelector: '#slider-prev',
        nextText: '<i class="fa fa-angle-right fa-lg"></i>',
        prevText: '<i class="fa fa-angle-left fa-lg"></i>'
    });
});

$('a.info').tooltip();
$(window).load(function() {
    $('.flexslider').flexslider({
        animation: "slide",
        start: function(slider) {
            $('body').removeClass('loading');
        }
    });
});
$(document).ready(function() {
    $("#owl-demo").owlCarousel({
        items: 4,
        itemsDesktop : 3,
        loop: true,
        autoPlay: false,
        stopOnHover: true,
    });
});
jQuery(document).ready(function(){
    jQuery('ul.superfish').superfish();
});

new WOW().init();

$(window).scroll(function() {
        $('#skillz').each(function(){
                var imagePos = $(this).offset().top;
                var viewportheight = window.innerHeight;
                var topOfWindow = $(window).scrollTop();
                if (imagePos < topOfWindow+viewportheight) {
                    $('.skill_bar').fadeIn('slow');
                    $('.skill_one').animate({
                            width:'30%'}
                        , 700);
                    $('.skill_two').animate({
                            width:'40%'}
                        , 700);
                    $('.skill_three').animate({
                            width:'75%'}
                        , 1200);
                    $('.skill_four').animate({
                            width:'35%'}
                        , 900);
                    $('.skill_bar_progress p').fadeIn('slow',function(){});
                }
            }
        );
    }
);